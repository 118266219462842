import React, { useMemo, useState } from 'react';
import {
  DataGridPro,
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-pro';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { usePersistedDataStore } from '../../store';
import { fields } from '../../../common/useStreamProcessorNew';
import BackToAnalysisButton from '../BackToAnalysisButton';
import MarketingInputs from './MarketingInputs'


// idk if we care about enforcing this minimum revenue amount.
const minimumRecentSaleRevenue = 100;
const CustomToolbar = ({ MuniCode, isFetching, totalCases, currentCount, marketingResponse, minimumThreshold, highTaxCutoff }) => {
  // const minimumSavings = usePersistedDataStore((state) => state.minimumSavings);

  const getFilteredRows = (rows, threshold) => {
    if (!rows) return [];

    // if you dont include a minimum threshold, it will export all.
    if (!threshold) {
      return [...rows].sort((a, b) => b.ProjectedAventineRevenue - a.ProjectedAventineRevenue);
    }

    const filteredRows = [...rows].filter(row => (
      // Include if meets revenue threshold AND has letter type
      (row.ProjectedAventineRevenue >= threshold && 
       row.LetterType && 
       row.LetterType.trim() !== '') ||
      // OR if it's a high tax case
      (row.Taxes >= highTaxCutoff && 
       (!row.LetterType?.toLowerCase().includes('regularmail') && 
        !row.LetterType?.toLowerCase().includes('recentsale') || 
        row.CaseDescription?.toLowerCase().includes('hightax'))) ||
      // OR if it's a recent sale meeting minimum revenue requirement
      (row.LetterType?.toLowerCase().includes('recentsale') && 
       row.ProjectedAventineRevenue >= minimumRecentSaleRevenue && 
       row.CaseDescription !== "BadRecentSale")
    ));

    return filteredRows.sort((a, b) => b.ProjectedAventineRevenue - a.ProjectedAventineRevenue);
  };

  const getRowsToExport = () => {
    return getFilteredRows(marketingResponse, minimumThreshold).map(row => row.id);
  };


  return (
    <div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', justifyContent: 'space-between' }}>
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarExport 
          csvOptions={{
            fileName: `${MuniCode}_Marketing`,
            getRowsToExport: () => getRowsToExport()
          }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
        {isFetching && (
          <>
            <Typography variant="body2">
              Calculated {currentCount || 0} out of {totalCases || 'loading...'}
            </Typography>
            <CircularProgress size={16} />
          </>
        )}
      </div>
      <div>
        <GridToolbarQuickFilter />
      </div>
    </div>
  );
};

export default function MarketingTable({MuniCode, countyString, muniString, TaxYear}) {
  const getMarketingResponse = usePersistedDataStore((state) => state.marketingResponse);
  const getTotalCases = usePersistedDataStore((state) => state.totalCases);
  const getIsFetching = usePersistedDataStore((state) => state.isFetching);

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [minimumThreshold, setMinimumThreshold] = useState(0);
  const [highTaxCutoff, setHighTaxCutoff] = useState(30000);

  const columns = useMemo(() => {
    return fields.map(({ field, headerName, width, type }) => ({
      field,
      headerName,
      width: width || 150,
      valueFormatter: type === 'numeric'
        ? (value) => {
            if (!value && value !== 0) return '';
            if (isNaN(Number(value))) return value;
            return Math.round(Number(value)).toLocaleString();
          }
        : type === 'percent'
        ? (value) => {
            if (!value && value !== 0) return '';
            if (isNaN(Number(value))) return value;
            return `${Number(value*100).toFixed(2)}%`;
          }
        : undefined,
    }));
  }, []);

  useMemo(() => {
    if (Object.keys(columnVisibilityModel).length === 0) {
      setColumnVisibilityModel(
        fields.reduce((acc, { field }) => {
          acc[field] = true;
          return acc;
        }, {})
      );
    }
  }, [fields, columnVisibilityModel]);

  const areaTitle = muniString !== "All" ? muniString : countyString;

  return (
    <div className="h-full flex flex-col ">
      <Box 
        display="flex" 
        flexDirection="column" 
        alignItems="center" 
        className="w-full"
      >
        <Box display="flex" width="100%" flexDirection="column" alignItems="center" my={!getIsFetching ? 2 : 0}>
          <Box 
            // set this to be max width xl
            display="flex" 
            width="100%" 
            alignItems="center" 
            justifyContent="space-between"
            sx={{ 
              minHeight: getIsFetching ? '64px' : 'auto',
              '& .MuiButtonBase-root': {
                alignSelf: 'center'
              }
            }}
          >
            <BackToAnalysisButton />
            <Typography variant="h4" sx={{textAlign: 'center' }}>
              {areaTitle} {TaxYear} Marketing
            </Typography>
            <Box sx={{ width: { xs: '40px', sm: '200px' } }} /> 
          </Box>
        </Box>

        <MarketingInputs 
          marketingResponse={getMarketingResponse}
          title={areaTitle} 
          minimumThreshold={minimumThreshold}
          setMinimumThreshold={setMinimumThreshold}
          minimumRecentSaleRevenue={minimumRecentSaleRevenue}
          highTaxCutoff={highTaxCutoff}
          setHighTaxCutoff={setHighTaxCutoff}
        />
      </Box>

      {/* Data Grid */}
      <DataGridPro
        rows={getMarketingResponse || []}
        columns={columns}
        rowBuffer={5}
        style={{ 
          border: '1px solid rgba(224, 224, 224, 1)'
        }}
        slots={{ 
          toolbar: () => (
            <CustomToolbar 
              MuniCode={MuniCode} 
              isFetching={getIsFetching}
              totalCases={getTotalCases}
              currentCount={getMarketingResponse?.length}
              marketingResponse={getMarketingResponse}
              minimumThreshold={minimumThreshold}
              highTaxCutoff={highTaxCutoff}
            />
          ) 
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
        checkboxSelection
        loading={!getMarketingResponse || getMarketingResponse.length === 0}
      />
    </div>
  );
}
