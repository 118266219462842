import './wdyr';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './components/App';
import './satoshi.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {ErrorBoundary} from 'react-error-boundary'
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// import { AuthProvider } from './components/AuthContextProvider';
import { UserProvider } from './components/UserContext';
import {StableRouterContextProvider} from './common/StableRouterContextProvider.js'


// import { initMixpanel } from '@propriety/analytics';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// a little dumb to do it like this?
// Run version check
// checkVersion();

window.addEventListener('error', (e) => {
  if (e.message.includes('Unexpected token <')) {
      window.location.reload(true); // Force a reload from the server
      alert('An error occurred, press control + Shift + R to reload the page.');
  }
});


// turn this error fallback into a proper "within your application" 404 error type page
// Custom error fallback component to display errors
function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div className="text-red-500 text-xl mt-8 m-auto text-center font-bold">
      <h2>Something went wrong:</h2>
      <pre>{error.message}</pre>
      <button
        onClick={resetErrorBoundary}
        className="mt-4 p-2 bg-blue-500 text-white rounded"
      >
        Try again
      </button>
    </div>
  );
}

// Initialize Mixpanel as early as possible
// initMixpanel(
//   process.env.REACT_APP_MIXPANEL_TOKEN,
//   // process.env.REACT_APP_TRACKING_DOMAIN // remove this, as we want to always track and we will chagne keys for prod.
// );

root.render(
  <BrowserRouter>
  <GoogleOAuthProvider clientId="511916914273-ip82hd3h57cjkjc00o6qoromqh89d32e.apps.googleusercontent.com">
  <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error, info) => {
          console.error("Error Boundary caught an error:", error);
          console.error("Error Info:", info);
          toast.error(`Unexpected error: ${error.message}`);
        }}
      >
      {/* custom stable usenavigate + location context */}
      <StableRouterContextProvider>
      {/* User auth context */}
      <UserProvider>
        {/* <AuthProvider> */}
        <App/>
        {/* </AuthProvider> */}
      </UserProvider>
    </StableRouterContextProvider>
    </ErrorBoundary>
    </GoogleOAuthProvider>
  </BrowserRouter>
);

// Re-enable and modify the service worker registration
serviceWorkerRegistration.register({
  onUpdate: async registration => {
    toast.info(
      'New version available! Click here to update', {
      autoClose: false,
      onClick: async () => {
        if (registration && registration.waiting) {
          await registration.unregister();
          registration.waiting.postMessage({ type: 'SKIP_WAITING' });
          // Clear all caches
          await caches.keys().then(function(names) {
            for (let name of names) caches.delete(name);
          });
          window.location.reload();
        }
      }
    });
  }
});

reportWebVitals();