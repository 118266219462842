import React, { useState, useEffect, useMemo } from 'react';
import { Slider, Typography, Box, Grid, TextField } from '@mui/material';
import axiosInstance from '../../axiosConfig';
import Button from '@mui/material/Button';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { usePersistedDataStore } from '../store'


const WeightsViewRebuilt = ({ onClose, runAnalysis }) => {
    const [weightsData, setWeightsData] = useState({
        optim_params: {},
        weights: {},
    });
    const getNegotiationObj = usePersistedDataStore(state => state.negotiationObj)
    const getCompSheet = usePersistedDataStore(state => state.compSheet)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = useMemo(() => ( parseInt(queryParams.get('comp')) - 1 || 0), [queryParams]);
    const saveWeights = async (weights) => {

        try {
            // Update weights, reconstruct the negotiation obj from update inventory (to have proper uid reordering)

            const updateNegotiationObj = {
                ...getNegotiationObj, // Start with the current negotiation object
                ...weights
              };

            onClose();
            const weightsResponse = await axiosInstance.post('/update_weights', updateNegotiationObj);
            console.log(weightsResponse);
            if(weightsResponse.status === 200){
                toast.success('Weights saved, re-running:', weights);
                runAnalysis();
            }
            else{
                toast.error('Error saving weights:', weightsResponse.data.message);
            }
        } catch (error) {
            toast.error('Error saving weights:', error);
        }

        // After it has been run, it should re-run analysis
        onClose();
    }

    useEffect(() => {
        (async () => {
            try {
                const response = await axiosInstance.get('/load_weights');
                const sortedWeights = {
                    weights: Object.fromEntries(
                        Object.entries(response.data.weights).sort(([, a], [, b]) => b - a)
                    ),
                    optim_params: Object.fromEntries(
                        Object.entries(response.data.optim_params).sort(([, a], [, b]) => b - a)
                    ),
                };
                setWeightsData(sortedWeights);
                console.log('Fetched and sorted weights:', sortedWeights);
            } catch (error) {
                console.error('Error fetching weights:', error);
            }
        })();
    }, []);

    const handleSliderChange = (category, name) => (event, newValue) => {
        setWeightsData((prevState) => ({
            ...prevState,
            [category]: {
                ...prevState[category],
                [name]: newValue,
            },
        }));
    };

    const handleInputChange = (category, name) => (event) => {
        const newValue = parseFloat(event.target.value);
        if (newValue >= 0 && newValue <= 100) {
            setWeightsData((prevState) => ({
                ...prevState,
                [category]: {
                    ...prevState[category],
                    [name]: newValue,
                },
            }));
        }
    };

    const renderRow = (category, data) =>
        Object.entries(data).map(([key, value]) => (
            <Box
                key={key}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    paddingY: 0.5,
                }}
            >
                <Typography sx={{ minWidth: 110, fontSize: '0.9rem' }}>{key}</Typography>
                <TextField
                    value={value}
                    onChange={handleInputChange(category, key)}
                    type="number"
                    inputProps={{ min: 0, max: 100, step: 0.01 }}
                    size="small"
                    sx={{
                        width: 100,
                        '& .MuiInputBase-root': { height: 28 }, // Compress input height
                    }}
                />
                <Slider
                    value={value}
                    onChange={handleSliderChange(category, key)}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    min={0}
                    max={10}
                    step={0.1}
                    sx={{ minWidth: 200 }}
                />
            </Box>
        ));

    return (
        <div>
            <Box sx={{ paddingX: 2 }}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom align="center">
                            Regular Parameters
                        </Typography>
                        {renderRow('weights', weightsData.weights)}
                    </Grid>
                    
                    <Grid item xs={12} md={6}>
                        <Typography variant="h6" gutterBottom align="center">
                            Optimization Parameters
                        </Typography>
                        {renderRow('optim_params', weightsData.optim_params)}
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
                <Button color="primary" variant="contained" onClick={() => saveWeights(weightsData)}>
                    Save
                </Button>
            </Box>
        </div>
    );
};

export default WeightsViewRebuilt;