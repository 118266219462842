import { useMemo } from "react";

export const useNYData = () => {
    const nyData = useMemo(() => ({
            "Columbia": {
                "selectVal": "C",
                "nameWithSelectVal": "Columbia (C)",
                "municipalities": {
                    "Hudson": {
                        "selectVal": "06",
                        "dbValue": "",
                        "nameWithSelectVal": "Hudson (06)"
                    },
                    "Ancram": {
                        "selectVal": "20",
                        "dbValue": "",
                        "nameWithSelectVal": "Ancram (20)"
                    },
                    "Austerlitz": {
                        "selectVal": "22",
                        "dbValue": "",
                        "nameWithSelectVal": "Austerlitz (22)"
                    },
                    "Canaan": {
                        "selectVal": "24",
                        "dbValue": "",
                        "nameWithSelectVal": "Canaan (24)"
                    },
                    "Chatham": {
                        "selectVal": "26",
                        "dbValue": "",
                        "nameWithSelectVal": "Chatham (26)"
                    },
                    "Claverack": {
                        "selectVal": "28",
                        "dbValue": "",
                        "nameWithSelectVal": "Claverack (28)"
                    },
                    "Clermont": {
                        "selectVal": "30",
                        "dbValue": "",
                        "nameWithSelectVal": "Clermont (30)"
                    },
                    "Copake": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Copake (32)"
                    },
                    "Gallatin": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Gallatin (34)"
                    },
                    "Germantown": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Germantown (36)"
                    },
                    "Ghent": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Ghent (38)"
                    },
                    "Greenport": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Greenport (40)"
                    },
                    "Hillsdale": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hillsdale (42)"
                    },
                    "Kinderhook": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Kinderhook (44)"
                    },
                    "Livingston": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Livingston (46)"
                    },
                    "New Lebanon": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "New Lebanon (48)"
                    },
                    "Stockport": {
                        "selectVal": "50",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Stockport (50)"
                    },
                    "Stuyvesant": {
                        "selectVal": "52",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Stuyvesant (52)"
                    },
                    "Taghkanic": {
                        "selectVal": "54",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Taghkanic (54)"
                    }
                }
            },
            "Delaware": {
                "selectVal": "L",
                "nameWithSelectVal": "Delaware (L)",
                "municipalities": {
                    "Andes": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Andes (20)"
                    },
                    "Bovina": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Bovina (22)"
                    },
                    "Colchester": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Colchester (24)"
                    },
                    "Davenport": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Davenport (26)"
                    },
                    "Delhi": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Delhi (28)"
                    },
                    "Deposit": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Deposit (30)"
                    },
                    "Franklin": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Franklin (32)"
                    },
                    "Hamden": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hamden (34)"
                    },
                    "Hancock": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hancock (36)"
                    },
                    "Harpersfield": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Harpersfield (38)"
                    },
                    "Kortright": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Kortright (40)"
                    },
                    "Masonville": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Masonville (42)"
                    },
                    "Meredith": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Meredith (44)"
                    },
                    "Middletown": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Middletown (46)"
                    },
                    "Roxbury": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Roxbury (48)"
                    },
                    "Sidney": {
                        "selectVal": "50",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Sidney (50)"
                    },
                    "Stamford": {
                        "selectVal": "52",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Stamford (52)"
                    },
                    "Tompkins": {
                        "selectVal": "54",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Tompkins (54)"
                    },
                    "Walton": {
                        "selectVal": "56",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Walton (56)"
                    }
                }
            },
            "Dutchess": {
                "selectVal": "D",
                "municipalities": {
                    "Beacon": {
                        "selectVal": "02",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Beacon (02)"
                    },
                    "Poughkeepsie (city)": {
                        "selectVal": "13",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Poughkeepsie (city) (13)"
                    },
                    "Amenia": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Amenia (20)"
                    },
                    "Beekman": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Beekman (22)"
                    },
                    "Clinton": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Clinton (24)"
                    },
                    "Dover": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Dover (26)"
                    },
                    "East Fishkill": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "East Fishkill (28)"
                    },
                    "Fishkill": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Fishkill (30)"
                    },
                    "Hyde Park": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hyde Park (32)"
                    },
                    "La Grange": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "La Grange (34)"
                    },
                    "Milan": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Milan (36)"
                    },
                    "Northeast": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Northeast (38)"
                    },
                    "Pawling": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Pawling (40)"
                    },
                    "Pine Plains": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Pine Plains (42)"
                    },
                    "Pleasant Valley": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Pleasant Valley (44)"
                    },
                    "Poughkeepsie": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Poughkeepsie (46)"
                    },
                    "Red Hook": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Red Hook (48)"
                    },
                    "Rhinebeck": {
                        "selectVal": "50",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Rhinebeck (50)"
                    },
                    "Stanford": {
                        "selectVal": "52",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Stanford (52)"
                    },
                    "Union Vale": {
                        "selectVal": "54",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Union Vale (54)"
                    },
                    "Wappinger": {
                        "selectVal": "56",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Wappinger (56)"
                    },
                    "Washington": {
                        "selectVal": "58",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Washington (58)"
                    }
                },
                "nameWithSelectVal": "Dutchess (D)"
            },
            "Greene": {
                "selectVal": "G",
                "municipalities": {
                    "Ashland": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Ashland (20)"
                    },
                    "Athens": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Athens (22)"
                    },
                    "Cairo": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Cairo (24)"
                    },
                    "Catskill": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Catskill (26)"
                    },
                    "Coxsackie": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Coxsackie (28)"
                    },
                    "Durham": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Durham (30)"
                    },
                    "Greenville": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Greenville (32)"
                    },
                    "Halcott": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Halcott (34)"
                    },
                    "Hunter": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hunter (36)"
                    },
                    "Jewett": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Jewett (38)"
                    },
                    "Lexington": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Lexington (40)"
                    },
                    "New Baltimore": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "New Baltimore (42)"
                    },
                    "Prattsville": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Prattsville (44)"
                    },
                    "Windham": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Windham (46)"
                    }
                },
                "nameWithSelectVal": "Greene (G)"
            },
            "Nassau": {
                "selectVal": "N",
                "municipalities": {
                    "Glen Cove City": {
                        "selectVal": "05",
                        "dbValue": "",
                        "nameWithSelectVal": "Glen Cove City (05)"
                    },
                    "Glen Cove": {
                        "selectVal": "06",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Glen Cove (06)"
                    },
                    "Long Beach City": {
                        "selectVal": "09",
                        "dbValue": "",
                        "nameWithSelectVal": "Long Beach City (09)"
                    },
                    "Long Beach": {
                        "selectVal": "10",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Long Beach (10)"
                    },
                    "Hempstead": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Bellerose": {
                                "selectVal": "2001",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Bellerose (2001)"
                            },
                            "Cedarhurst": {
                                "selectVal": "2003",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Cedarhurst (2003)"
                            },
                            "East Rockaway": {
                                "selectVal": "2005",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "East Rockaway (2005)"
                            },
                            "Floral Park": {
                                "selectVal": "2007",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Floral Park (2007)"
                            },
                            "Freeport": {
                                "selectVal": "2009",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Freeport (2009)"
                            },
                            "Garden City": {
                                "selectVal": "2011",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Garden City (2011)"
                            },
                            "Hempstead (Village)": {
                                "selectVal": "2013",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Hempstead (Village) (2013)"
                            },
                            "Hewlett Bay Park": {
                                "selectVal": "2015",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Hewlett Bay Park (2015)"
                            },
                            "Hewlett Harbor": {
                                "selectVal": "2017",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Hewlett Harbor (2017)"
                            },
                            "Hewlett Neck": {
                                "selectVal": "2019",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Hewlett Neck (2019)"
                            },
                            "Island Park": {
                                "selectVal": "2021",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Island Park (2021)"
                            },
                            "Lawrence": {
                                "selectVal": "2023",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lawrence (2023)"
                            },
                            "Lynbrook": {
                                "selectVal": "2025",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lynbrook (2025)"
                            },
                            "Malverne": {
                                "selectVal": "2027",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Malverne (2027)"
                            },
                            "Rockville Centre": {
                                "selectVal": "2029",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Rockville Centre (2029)"
                            },
                            "South Floral Park": {
                                "selectVal": "2031",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "South Floral Park (2031)"
                            },
                            "Stewart Manor": {
                                "selectVal": "2033",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Stewart Manor (2033)"
                            },
                            "Valley Stream": {
                                "selectVal": "2035",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Valley Stream (2035)"
                            },
                            "Woodsburg": {
                                "selectVal": "2037",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Woodsburg (2037)"
                            },
                            "Mineola": {
                                "selectVal": "2039",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Mineola (2039)"
                            },
                            "New Hyde Park": {
                                "selectVal": "2041",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "New Hyde Park (2041)"
                            },
                            "Atlantic Beach": {
                                "selectVal": "2043",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Atlantic Beach (2043)"
                            }
                        },
                        "nameWithSelectVal": "Hempstead (20)"
                    },
                    "North Hempstead": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Baxter Estates": {
                                "selectVal": "2201",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Baxter Estates (2201)"
                            },
                            "East Hills": {
                                "selectVal": "2203",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "East Hills (2203)"
                            },
                            "East Williston": {
                                "selectVal": "2205",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "East Williston (2205)"
                            },
                            "Flower Hill": {
                                "selectVal": "2207",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Flower Hill (2207)"
                            },
                            "Great Neck": {
                                "selectVal": "2209",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Great Neck (2209)"
                            },
                            "Great neck Estates": {
                                "selectVal": "2211",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Great neck Estates (2211)"
                            },
                            "Great Neck Plaza": {
                                "selectVal": "2213",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Great Neck Plaza (2213)"
                            },
                            "Kensington": {
                                "selectVal": "2215",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Kensington (2215)"
                            },
                            "Kings Point": {
                                "selectVal": "2217",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Kings Point (2217)"
                            },
                            "Lake Success": {
                                "selectVal": "2219",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lake Success (2219)"
                            },
                            "Manor Haven": {
                                "selectVal": "2221",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Manor Haven (2221)"
                            },
                            "Mineola": {
                                "selectVal": "2223",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Mineola (2223)"
                            },
                            "Munsey Park": {
                                "selectVal": "2225",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Munsey Park (2225)"
                            },
                            "New Hyde Park": {
                                "selectVal": "2227",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "New Hyde Park (2227)"
                            },
                            "North Hills": {
                                "selectVal": "2229",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "North Hills (2229)"
                            },
                            "Old Westbury": {
                                "selectVal": "2231",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Old Westbury (2231)"
                            },
                            "Plandome": {
                                "selectVal": "2233",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Plandome (2233)"
                            },
                            "Plandome Heights": {
                                "selectVal": "2235",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Plandome Heights (2235)"
                            },
                            "Plandome Manor": {
                                "selectVal": "2237",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Plandome Manor (2237)"
                            },
                            "Port Washington North": {
                                "selectVal": "2239",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Port Washington North (2239)"
                            },
                            "Roslyn": {
                                "selectVal": "2241",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Roslyn (2241)"
                            },
                            "Roslyn Estates": {
                                "selectVal": "2243",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Roslyn Estates (2243)"
                            },
                            "Roslyn Harbor": {
                                "selectVal": "2245",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Roslyn Harbor (2245)"
                            },
                            "Russell Gardens": {
                                "selectVal": "2247",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Russell Gardens (2247)"
                            },
                            "Saddle Rock": {
                                "selectVal": "2249",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Saddle Rock (2249)"
                            },
                            "Sands Point": {
                                "selectVal": "2251",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Sands Point (2251)"
                            },
                            "Thomaston": {
                                "selectVal": "2253",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Thomaston (2253)"
                            },
                            "Westbury": {
                                "selectVal": "2255",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Westbury (2255)"
                            },
                            "Williston Park": {
                                "selectVal": "2257",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Williston Park (2257)"
                            },
                            "Floral Park": {
                                "selectVal": "2259",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Floral Park (2259)"
                            },
                            "Garden City": {
                                "selectVal": "2261",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Garden City (2261)"
                            }
                        },
                        "nameWithSelectVal": "North Hempstead (22)"
                    },
                    "Oyster Bay": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Bayville": {
                                "selectVal": "2401",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Bayville (2401)"
                            },
                            "Brookville": {
                                "selectVal": "2403",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Brookville (2403)"
                            },
                            "Centre Island": {
                                "selectVal": "2405",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Centre Island (2405)"
                            },
                            "Cove Neck": {
                                "selectVal": "2407",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Cove Neck (2407)"
                            },
                            "East Hills": {
                                "selectVal": "2408",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "East Hills (2408)"
                            },
                            "Farmingdale": {
                                "selectVal": "2409",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Farmingdale (2409)"
                            },
                            "Lattingtown": {
                                "selectVal": "2411",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lattingtown (2411)"
                            },
                            "Laurel Hollow": {
                                "selectVal": "2413",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Laurel Hollow (2413)"
                            },
                            "Old Brookville": {
                                "selectVal": "2415",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Old Brookville (2415)"
                            },
                            "Massapequa Park": {
                                "selectVal": "2417",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Massapequa Park (2417)"
                            },
                            "Oyster Bay Cove": {
                                "selectVal": "2419",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Oyster Bay Cove (2419)"
                            },
                            "Matinecock": {
                                "selectVal": "2421",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Matinecock (2421)"
                            },
                            "Sea Cliff": {
                                "selectVal": "2423",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Sea Cliff (2423)"
                            },
                            "Mill Neck": {
                                "selectVal": "2425",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Mill Neck (2425)"
                            },
                            "Upper Brookville": {
                                "selectVal": "2427",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Upper Brookville (2427)"
                            },
                            "Muttontown": {
                                "selectVal": "2429",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Muttontown (2429)"
                            },
                            "Old Westbury": {
                                "selectVal": "2431",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Old Westbury (2431)"
                            }
                        },
                        "nameWithSelectVal": "Oyster Bay (24)"
                    }
                },
                "nameWithSelectVal": "Nassau (N)"
            },
            "Orange": {
                "selectVal": "O",
                "municipalities": {
                    "Middletown": {
                        "selectVal": "09",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Middletown (09)"
                    },
                    "Newburgh (City)": {
                        "selectVal": "11",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Newburgh (City) (11)"
                    },
                    "Port Jervis": {
                        "selectVal": "13",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Port Jervis (13)"
                    },
                    "Blooming Grove": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Blooming Grove (20)"
                    },
                    "Chester": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Chester (22)"
                    },
                    "Cornwall": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Cornwall (24)"
                    },
                    "Crawford": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Crawford (26)"
                    },
                    "Deerpark": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Deerpark (28)"
                    },
                    "Goshen": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Goshen (30)"
                    },
                    "Greenville": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Greenville (32)"
                    },
                    "Hamptonburgh": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hamptonburgh (34)"
                    },
                    "Highlands": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Highlands (36)"
                    },
                    "Minisink": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Minisink (38)"
                    },
                    "Monroe": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Monroe (40)"
                    },
                    "Montgomery": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Montgomery (42)"
                    },
                    "Mount Hope": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Mount Hope (44)"
                    },
                    "Newburgh": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Newburgh (46)"
                    },
                    "New Windsor": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "New Windsor (48)"
                    },
                    "Tuxedo": {
                        "selectVal": "50",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Tuxedo Park": {
                                "selectVal": "5001",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Tuxedo Park (5001)"
                            }
                        },
                        "nameWithSelectVal": "Tuxedo (50)"
                    },
                    "Wallkill": {
                        "selectVal": "52",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Wallkill (52)"
                    },
                    "Warwick": {
                        "selectVal": "54",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Florida": {
                                "selectVal": "5401",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Florida (5401)"
                            },
                            "Greenwood Lake": {
                                "selectVal": "5403",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Greenwood Lake (5403)"
                            },
                            "Warwick": {
                                "selectVal": "5405",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Warwick (5405)"
                            }
                        },
                        "nameWithSelectVal": "Warwick (54)"
                    },
                    "Wawayanda": {
                        "selectVal": "56",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Wawayanda (56)"
                    },
                    "Woodbury": {
                        "selectVal": "58",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Woodbury (58)"
                    },
                    "Palm Tree": {
                        "selectVal": "60",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Palm Tree (60)"
                    }
                },
                "nameWithSelectVal": "Orange (O)"
            },
            "Putnam": {
                "selectVal": "P",
                "municipalities": {
                    "Carmel": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Carmel (20)"
                    },
                    "Kent": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Kent (22)"
                    },
                    "Patterson": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Patterson (24)"
                    },
                    "Philipstown": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Philipstown (26)"
                    },
                    "Putnam Valley": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Putnam Valley (28)"
                    },
                    "Southeast": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Southeast (30)"
                    }
                },
                "nameWithSelectVal": "Putnam (P)"
            },
            "Rockland": {
                "selectVal": "R",
                "COD": "4.19",
                "PRD": "0.88",
                "averageMisassessment": "13.9%",
                "municipalities": {
                    "Clarkstown": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "villages": {
                            "Upper Nyack": {
                                "selectVal": "2001",
                                "COD": "41.89",
                                "PRD": "1.04",
                                "averageMisassessment": "17.4%",
                                "nameWithSelectVal": "Upper Nyack (2001)"
                            },
                            "Spring Valley": {
                                "selectVal": "2003",
                                "COD": "12.00",
                                "PRD": "1.81",
                                "averageMisassessment": "8.2%",
                                "nameWithSelectVal": "Spring Valley (2003)"
                            }
                        },
                        "nameWithSelectVal": "Clarkstown (20)"
                    },
                    "Haverstraw": {
                        "selectVal": "22",
                        "COD": "9.12",
                        "PRD": "1.76",
                        "averageMisassessment": "21.4%",
                        "nameWithSelectVal": "Haverstraw (22)",
                        "villages": {
                            "Haverstraw": {
                                "selectVal": "2201",
                                "nameWithSelectVal": "Haverstraw (2201)"
                            }
                        }
                    },
                    "Orangetown": {
                        "selectVal": "24",
                        "COD": "8.88",
                        "PRD": "1.04",
                        "averageMisassessment": "3.3%",
                        "villages": {
                            "Piermont": {
                                "selectVal": "2405",
                                "COD": "82.44",
                                "PRD": "0.64",
                                "averageMisassessment": "25.81%",
                                "nameWithSelectVal": "Piermont (2405)"
                            }
                        },
                        "nameWithSelectVal": "Orangetown (24)"
                    },
                    "Ramapo": {
                        "selectVal": "26",
                        "COD": "17.23",
                        "PRD": "1.42",
                        "averageMisassessment": "15.27%",
                        "villages": {
                            "Hillburn": {
                                "selectVal": "2601",
                                "COD": "4.81",
                                "PRD": "1.02",
                                "averageMisassessment": "2.3%",
                                "nameWithSelectVal": "Hillburn (2601)"
                            },
                            "Spring Valley": {
                                "selectVal": "2605",
                                "nameWithSelectVal": "Spring Valley (2605)"
                            }
                        },
                        "nameWithSelectVal": "Ramapo (26)"
                    },
                    "Stony Point": {
                        "selectVal": "28",
                        "COD": "19.96",
                        "PRD": "1.05",
                        "averageMisassessment": "9.13%",
                        "nameWithSelectVal": "Stony Point (28)"
                    }
                },
                "nameWithSelectVal": "Rockland (R)"
            },
            "Suffolk": {
                "selectVal": "S",
                "municipalities": {
                    "Babylon": {
                        "selectVal": "01",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Amityville": {
                                "selectVal": "0101",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Amityville (0101)"
                            },
                            "Babylon (Village)": {
                                "selectVal": "0102",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Babylon (Village) (0102)"
                            },
                            "Lindenhurst": {
                                "selectVal": "0103",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lindenhurst (0103)"
                            }
                        },
                        "nameWithSelectVal": "Babylon (01)"
                    },
                    "Brookhaven": {
                        "selectVal": "02",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Belle Terre": {
                                "selectVal": "0201",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Belle Terre (0201)"
                            },
                            "Bellport": {
                                "selectVal": "0202",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Bellport (0202)"
                            },
                            "Old Field": {
                                "selectVal": "0203",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Old Field (0203)"
                            },
                            "Patchogue": {
                                "selectVal": "0204",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Patchogue (0204)"
                            },
                            "Poquott": {
                                "selectVal": "0205",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Poquott (0205)"
                            },
                            "Port Jefferson": {
                                "selectVal": "0206",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Port Jefferson (0206)"
                            },
                            "Shoreham": {
                                "selectVal": "0207",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Shoreham (0207)"
                            },
                            "Lake Grove": {
                                "selectVal": "0208",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lake Grove (0208)"
                            }
                        },
                        "nameWithSelectVal": "Brookhaven (02)"
                    },
                    "East Hampton": {
                        "selectVal": "03",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "East Hampton (village)": {
                                "selectVal": "0301",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "East Hampton (village) (0301)"
                            },
                            "Sag harbor": {
                                "selectVal": "0302",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Sag harbor (0302)"
                            }
                        },
                        "nameWithSelectVal": "East Hampton (03)"
                    },
                    "Huntington": {
                        "selectVal": "04",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Asharoken": {
                                "selectVal": "0401",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Asharoken (0401)"
                            },
                            "Huntington Bay": {
                                "selectVal": "0402",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Huntington Bay (0402)"
                            },
                            "Lloyd Harbor": {
                                "selectVal": "0403",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Lloyd Harbor (0403)"
                            },
                            "Northport": {
                                "selectVal": "0404",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Northport (0404)"
                            }
                        },
                        "nameWithSelectVal": "Huntington (04)"
                    },
                    "Islip": {
                        "selectVal": "05",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Brightwaters": {
                                "selectVal": "0501",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Brightwaters (0501)"
                            },
                            "Ocean Beach": {
                                "selectVal": "0502",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Ocean Beach (0502)"
                            },
                            "Saltaire": {
                                "selectVal": "0503",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Saltaire (0503)"
                            },
                            "Islandia": {
                                "selectVal": "0504",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Islandia (0504)"
                            }
                        },
                        "nameWithSelectVal": "Islip (05)"
                    },
                    "Riverhead": {
                        "selectVal": "06",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Riverhead (06)"
                    },
                    "Shelter Island": {
                        "selectVal": "07",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Dering Harbor": {
                                "selectVal": "0701",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Dering Harbor (0701)"
                            }
                        },
                        "nameWithSelectVal": "Shelter Island (07)"
                    },
                    "Smithtown": {
                        "selectVal": "08",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Head-of-the-Harbor": {
                                "selectVal": "0801",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Head-of-the-Harbor (0801)"
                            },
                            "Nissequogue": {
                                "selectVal": "0802",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Nissequogue (0802)"
                            },
                            "Village of the Branch": {
                                "selectVal": "0803",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Village of the Branch (0803)"
                            }
                        },
                        "nameWithSelectVal": "Smithtown (08)"
                    },
                    "Southampton": {
                        "selectVal": "09",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "North Haven": {
                                "selectVal": "0901",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "North Haven (0901)"
                            },
                            "Quogue": {
                                "selectVal": "0902",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Quogue (0902)"
                            },
                            "Sag Harbor": {
                                "selectVal": "0903",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Sag Harbor (0903)"
                            },
                            "Southampton (Village)": {
                                "selectVal": "0904",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Southampton (Village) (0904)"
                            },
                            "West Hampton Beach": {
                                "selectVal": "0905",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "West Hampton Beach (0905)"
                            },
                            "West Hampton Dunes": {
                                "selectVal": "0907",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "West Hampton Dunes (0907)"
                            },
                            "Sagaponack": {
                                "selectVal": "0908",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Sagaponack (0908)"
                            }
                        },
                        "nameWithSelectVal": "Southampton (09)"
                    },
                    "Southold": {
                        "selectVal": "10",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Southold (Village)": {
                                "selectVal": "1001",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Southold (Village) (1001)"
                            }
                        },
                        "nameWithSelectVal": "Southold (10)"
                    }
                },
                "nameWithSelectVal": "Suffolk (S)"
            },
            "Sullivan": {
                "selectVal": "V",
                "municipalities": {
                    "Bethel": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Bethel (20)"
                    },
                    "Callicoon": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Callicoon (22)"
                    },
                    "Cochecton": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Cochecton (24)"
                    },
                    "Delaware": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Delaware (26)"
                    },
                    "Fallsburgh": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Fallsburgh (28)"
                    },
                    "Forestburgh": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Forestburgh (30)"
                    },
                    "Fremont": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Fremont (32)"
                    },
                    "Highland": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Highland (34)"
                    },
                    "Liberty": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Liberty (36)"
                    },
                    "Lumberland": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Lumberland (38)"
                    },
                    "Mamakating": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Mamakating (40)"
                    },
                    "Neversink": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Neversink (42)"
                    },
                    "Rockland": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Rockland (44)"
                    },
                    "Thompson": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Thompson (46)"
                    },
                    "Tusten": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Tusten (48)"
                    }
                },
                "nameWithSelectVal": "Sullivan (V)"
            },
            "Ulster": {
                "selectVal": "U",
                "municipalities": {
                    "Kingston (city)": {
                        "selectVal": "08",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Kingston (city) (08)"
                    },
                    "Denning": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Denning (20)"
                    },
                    "Esopus": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Esopus (22)"
                    },
                    "Gardiner": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Gardiner (24)"
                    },
                    "Hardenburgh": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hardenburgh (26)"
                    },
                    "Hurley": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Hurley (28)"
                    },
                    "Kingston": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Kingston (30)"
                    },
                    "Lloyd": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Lloyd (32)"
                    },
                    "Marbletown": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Marbletown (34)"
                    },
                    "Marlborough": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Marlborough (36)"
                    },
                    "New Paltz": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "New Paltz (38)"
                    },
                    "Olive": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Olive (40)"
                    },
                    "Plattekill": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Plattekill (42)"
                    },
                    "Rochester": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Rochester (44)"
                    },
                    "Rosendale": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Rosendale (46)"
                    },
                    "Saugerties": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Saugerties (48)"
                    },
                    "Shandaken": {
                        "selectVal": "50",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Shandaken (50)"
                    },
                    "Shawangunk": {
                        "selectVal": "52",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Shawangunk (52)"
                    },
                    "Ulster": {
                        "selectVal": "54",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Ulster (54)"
                    },
                    "Wawarsing": {
                        "selectVal": "56",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Wawarsing (56)"
                    },
                    "Woodstock": {
                        "selectVal": "58",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Woodstock (58)"
                    }
                },
                "nameWithSelectVal": "Ulster (U)"
            },
            "Westchester": {
                "selectVal": "W",
                "municipalities": {
                    "Mt Vernon": {
                        "selectVal": "08",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Mt Vernon (08)"
                    },
                    "New Rochelle": {
                        "selectVal": "10",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "New Rochelle (10)"
                    },
                    "Peekskill": {
                        "selectVal": "12",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Peekskill (12)"
                    },
                    "Rye (City)": {
                        "selectVal": "14",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Rye (City) (14)"
                    },
                    "White Plains": {
                        "selectVal": "17",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "White Plains (17)"
                    },
                    "Yonkers": {
                        "selectVal": "18",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Yonkers (18)"
                    },
                    "Bedford": {
                        "selectVal": "20",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Bedford (20)"
                    },
                    "Cortlandt": {
                        "selectVal": "22",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Buchanan": {
                                "selectVal": "2201",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Buchanan (2201)"
                            },
                            "Croton-on-Hudson": {
                                "selectVal": "2203",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Croton-on-Hudson (2203)"
                            }
                        },
                        "nameWithSelectVal": "Cortlandt (22)"
                    },
                    "Eastchester": {
                        "selectVal": "24",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Bronxville": {
                                "selectVal": "2401",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Bronxville (2401)"
                            },
                            "Tuckahoe": {
                                "selectVal": "2403",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Tuckahoe (2403)"
                            }
                        },
                        "nameWithSelectVal": "Eastchester (24)"
                    },
                    "Greenburgh": {
                        "selectVal": "26",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Greenburgh (26)"
                    },
                    "Harrison": {
                        "selectVal": "28",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Harrison (28)"
                    },
                    "Lewisboro": {
                        "selectVal": "30",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Lewisboro (30)"
                    },
                    "Mamaroneck": {
                        "selectVal": "32",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Larchmont": {
                                "selectVal": "3201",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Larchmont (3201)"
                            }
                        },
                        "nameWithSelectVal": "Mamaroneck (32)"
                    },
                    "Mount Pleasant": {
                        "selectVal": "34",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Sleepy Hollow": {
                                "selectVal": "3401",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Sleepy Hollow (3401)"
                            },
                            "Pleasantville": {
                                "selectVal": "3403",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Pleasantville (3403)"
                            }
                        },
                        "nameWithSelectVal": "Mount Pleasant (34)"
                    },
                    "New Castle": {
                        "selectVal": "36",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "New Castle (36)"
                    },
                    "North Castle": {
                        "selectVal": "38",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "North Castle (38)"
                    },
                    "North Salem": {
                        "selectVal": "40",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "North Salem (40)"
                    },
                    "Ossining": {
                        "selectVal": "42",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Ossining (42)"
                    },
                    "Pelham": {
                        "selectVal": "44",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Pelham (44)"
                    },
                    "Pound Ridge": {
                        "selectVal": "46",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Pound Ridge (46)"
                    },
                    "Rye": {
                        "selectVal": "48",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Rye (48)"
                    },
                    "Scarsdale": {
                        "selectVal": "50",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Scarsdale (50)"
                    },
                    "Somers": {
                        "selectVal": "52",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Somers (52)"
                    },
                    "Yorktown": {
                        "selectVal": "54",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "nameWithSelectVal": "Yorktown (54)"
                    },
                    "Mount Kisco": {
                        "selectVal": "56",
                        "COD": "14.9",
                        "PRD": "1.00",
                        "averageMisassessment": "9.1%",
                        "dbValue": "",
                        "villages": {
                            "Mount Kisco (Village)": {
                                "selectVal": "5601",
                                "COD": "14.9",
                                "PRD": "1.00",
                                "averageMisassessment": "9.1%",
                                "dbValue": "",
                                "nameWithSelectVal": "Mount Kisco (Village) (5601)"
                            }
                        },
                        "nameWithSelectVal": "Mount Kisco (56)"
                    }
                },
                "nameWithSelectVal": "Westchester (W)"
            }
        
    }),[])

    // functions to extract specific lists from this data:
    // functions to extract specific lists from this data:
    const getCounties = () =>
    Object.entries(nyData).map(([key, value]) => ({
        key,
        nameWithSelectVal: value.nameWithSelectVal,
        selectVal: value.selectVal,
    }));

    const getMunicipalities = (county) =>
    nyData[county]?.municipalities
        ? Object.entries(nyData[county].municipalities).map(([key, value]) => ({
            key,
            nameWithSelectVal: value.nameWithSelectVal,
            selectVal: value.selectVal,
        }))
        : [];

    const getVillages = (county, municipality) =>
    nyData[county]?.municipalities[municipality]?.villages
        ? Object.entries(nyData[county].municipalities[municipality].villages).map(([key, value]) => ({
            key,
            nameWithSelectVal: value.nameWithSelectVal,
            selectVal: value.selectVal,
        }))
        : [];



    // create a generic mapping function here, which does a lookup to go from nameWithSelectVal to SelectVal

return { nyData, getCounties, getMunicipalities, getVillages };
};



// UNUSED FIELDS FROM NYDATAOBJ:
// Albany: generateRandomValues(),
        // Albany is right, figure out how to copy it for all below in one fell swoop.
        // Allegany: generateRandomValues(),
        // Broome: generateRandomValues(),
        // Cattaraugus: generateRandomValues(),
        // Cayuga: generateRandomValues(),
        // Chautaugua: generateRandomValues(),
        // Chemung: generateRandomValues(),
        // Chenango: generateRandomValues(),
        // Clinton: generateRandomValues(),
        // Cortland: generateRandomValues(),
        // Erie: generateRandomValues(),
        // Essex: generateRandomValues(),
        // Franklin: generateRandomValues(),
        // Fulton: generateRandomValues(),
        // Genesee: generateRandomValues(),
        // Madison: generateRandomValues(),
        // Monroe: {
        //   selectVal: 'M',
        //     municipalities: {
        //       'Rochester (City)': {
        //         selectVal: '14',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Brighton: {
        //         selectVal: '20',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Chili: {
        //         selectVal: '22',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Clarkson: {
        //         selectVal: '24',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Gates: {
        //         selectVal: '26',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Greece: {
        //         selectVal: '28',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Hamlin: {
        //         selectVal: '30',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Henrietta: {
        //         selectVal: '32',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Irondequoit: {
        //         selectVal: '34',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Mendon: {
        //         selectVal: '36',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Ogden: {
        //         selectVal: '38',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Parma: {
        //         selectVal: '40',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Penfield: {
        //         selectVal: '42',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Perinton: {
        //         selectVal: '44',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Pittsford: {
        //         selectVal: '46',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Riga: {
        //         selectVal: '48',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Rush: {
        //         selectVal: '50',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Sweden: {
        //         selectVal: '52',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Webster: {
        //         selectVal: '54',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       Wheatland: {
        //         selectVal: '56',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //       'East Rochester': {
        //         selectVal: '58',
        //         COD: '14.9',
        //         PRD: '1.00',
        //         averageMisassessment: '9.1%',
        //         dbValue: ''
        //       },
        //     }
        // },
        // Montgomery: generateRandomValues(),
        // Niagara: generateRandomValues(),
        // Oneida: generateRandomValues(),
        // Onondaga: generateRandomValues(),
        // Ontario: generateRandomValues(),
        // Orleans: generateRandomValues(),
        // Oswego: generateRandomValues(),
        // Otsego: generateRandomValues(),
        // Rensselaer: generateRandomValues(),
        // 'St Lawrence': generateRandomValues(),
        // Saratoga: generateRandomValues(),
        // Schenectady: generateRandomValues(),
        // Schoharie: generateRandomValues(),
        // Schuyler: generateRandomValues(),
        // Seneca: generateRandomValues(),
        // Steuben: generateRandomValues(),
        // Tioga: generateRandomValues(),
        // Tompkins: generateRandomValues(),
        // Warren: generateRandomValues(),
        // Washington: generateRandomValues(),
        // Wayne: generateRandomValues(),
        // Wyoming: generateRandomValues(),
        // Yates: generateRandomValues(),




    //     const nyData = useMemo(() => ({
    //         "Columbia": {
    //             "selectVal": "C",
    //             "nameWithSelectVal": "Columbia (C)",
    //         },
    //         "Delaware": {
    //             "selectVal": "L",
    //             "nameWithSelectVal": "Delaware (L)",
    //         },
    //         "Dutchess": {
    //             "selectVal": "D",
    //             "nameWithSelectVal": "Dutchess (D)"
    //         },
    //         "Greene": {
    //             "selectVal": "G",
    //             "nameWithSelectVal": "Greene (G)"
    //         },
    //         "Nassau": {
    //             "selectVal": "N",
    //             "nameWithSelectVal": "Nassau (N)"
    //         },
    //         "Orange": {
    //             "selectVal": "O",
    //             "nameWithSelectVal": "Orange (O)"
    //         },
    //         "Putnam": {
    //             "selectVal": "P",
    //             "nameWithSelectVal": "Putnam (P)"
    //         },
    //         "Rockland": {
    //             "selectVal": "R",
    //             "COD": "4.19",
    //             "PRD": "0.88",
    //             "averageMisassessment": "13.9%",
    //             "nameWithSelectVal": "Rockland (R)"
    //         },
    //         "Suffolk": {
    //             "selectVal": "S",
    //             "nameWithSelectVal": "Suffolk (S)"
    //         },
    //         "Sullivan": {
    //             "selectVal": "V",
    //             "nameWithSelectVal": "Sullivan (V)"
    //         },
    //         "Ulster": {
    //             "selectVal": "U",
    //             "nameWithSelectVal": "Ulster (U)"
    //         },
    //         "Westchester": {
    //             "selectVal": "W",
    //             "nameWithSelectVal": "Westchester (W)"
    //         }
        
    // }),[])