import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const MiscAdjustmentModal = ({ toggleDisplayModal, parcelId, selectedComps, handleAddAdjustment }) => {
  const [adjustmentName, setAdjustmentName] = useState('');
  const [adjustmentValue, setAdjustmentValue] = useState('');
  const [adjustmentType, setAdjustmentType] = useState('%');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [error, setError] = useState('');

  const handleAdjustmentTypeChange = (event, newType) => {
    if (newType !== null) {
      setAdjustmentType(newType);
    }
  };

  const handleAdjustmentNameChange = (event) => {
    const value = event.target.value;
    if (value.length <= 15) {
      setAdjustmentName(value);
      setError('');
    } else {
      setError('The maximum name length is 15 characters.');
    }
  };

  const handleAdjustmentValueChange = (event) => {
    const inputValue = event.target.value;
    // Allow negative values and set the value with respect to adjustment type
    if (adjustmentType === '%') {
      setAdjustmentValue(inputValue.replace(/[^\d.-]/g, '')); // Ensures only numbers, dot, and minus
    } else {
      setAdjustmentValue(inputValue);
    }
  };

  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [id]: checked,
    }));
  };

  const handleAddAdjustmentClick = () => {
    if (!adjustmentName || !adjustmentValue) {
      setError('Please fill out all fields.');
      return;
    }

  // Start by checking if the subject is selected
  const isSubjectChecked = selectedCheckboxes[`checkbox-subject`] || false;
  const selectedProperties = [isSubjectChecked ? 1 : 0];

  // Iterate over selectedComps to add the values for other checkboxes
  const isAnyCheckboxSelected = selectedComps.reduce((acc, comp, index) => {
    const isChecked = selectedCheckboxes[`checkbox-${index + 1}`] || false;
    selectedProperties.push(isChecked ? 1 : 0);
    return acc || isChecked;
  }, false);

  if (!isSubjectChecked && !isAnyCheckboxSelected) {
    setError('Please select at least one property.');
    return;
  }

  setError('');
  const adjustmentData = {
    adjustmentName,
    adjustmentValue,
    adjustmentType,
    selectedProperties,
  };
    handleAddAdjustment(adjustmentData);
    toggleDisplayModal(false);
  };

  return (
    <div className='fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 z-50'>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white min-w-64 p-6 rounded-lg'>
        <div className='flex justify-between'>
          <h1>Add Misc Adjustment:</h1>
          <button onClick={() => toggleDisplayModal(false)}>X</button>
        </div>

        <div className='mt-2 w-[330px]'>
          <TextField 
            id="adjustment-name" 
            label="Adjustment Name" 
            placeholder='ex: Tennis Court'
            variant="outlined" 
            fullWidth
            value={adjustmentName}
            autoComplete='off'
            onChange={handleAdjustmentNameChange}
            InputLabelProps={{
              sx: { backgroundColor: 'transparent' },
            }}
            sx={{ backgroundColor: 'transparent' }}
          />
        </div>

        <div className='flex items-center mt-4'>

          {/* Value input */}
          <div className='mt-2 w-56'>
          <TextField
            id="adjustment-value"
            label="Adjustment Value"
            placeholder={adjustmentType === '$' ? '1,000' : '5'}
            type="text"
            fullWidth
            value={adjustmentValue}
            onChange={handleAdjustmentValueChange}
            helperText="Enter the value of the adjustment"
            sx={{ backgroundColor: 'transparent' }}
            InputLabelProps={{
              shrink: true,
              sx: { backgroundColor: 'transparent' },
            }}
            InputProps={{
              startAdornment: adjustmentType === '$' ? (
                <InputAdornment position="start">$</InputAdornment>
              ) : null,
              endAdornment: adjustmentType === '%' ? (
                <InputAdornment position="end">%</InputAdornment>
              ) : null,
            }}
            variant="outlined"
          />
        </div>

        {/* % or $ toggle */}
        <div className='w-24 ml-2 mb-3 items-center'>
          <ToggleButtonGroup
            value={adjustmentType}
            exclusive
            onChange={handleAdjustmentTypeChange}
            aria-label="Adjustment Type"
            fullWidth
          >
            <ToggleButton value="$" aria-label="Dollar Adjustment">
              $
            </ToggleButton>
            <ToggleButton value="%" aria-label="Percent Adjustment">
              %
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        
        </div>

        {error && <div className='mt-2 text-red-500'>{error}</div>}

        <div className='mt-2'>
          <span>Select the properties that have this feature:</span>
        </div>
        <div className='mt-2 flex flex-wrap'>
          <div className='flex items-center mb-2 w-24'>
            <input
              type='checkbox'
              id={`checkbox-subject`}
              checked={selectedCheckboxes[`checkbox-subject`] || false}
              onChange={handleCheckboxChange}
            />
            <label htmlFor={`checkbox-subject`} className='ml-2 bg-transparent'>Subject</label>
          </div>

          {selectedComps.map((comp, index) => (
            <div key={index} className='flex items-center mb-2 w-24'>
              <input
                type='checkbox'
                id={`checkbox-${index + 1}`}
                checked={selectedCheckboxes[`checkbox-${index + 1}`] || false}
                onChange={handleCheckboxChange}
              />
              <label htmlFor={`checkbox-${index + 1}`} className='ml-2 bg-transparent'>Comp {index + 1}</label>
            </div>
          ))}
        </div>

        <div className='mt-4'>
          <Button variant="contained" onClick={handleAddAdjustmentClick}>
            Add Adjustment
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MiscAdjustmentModal;
