import React, {useMemo, useCallback, useEffect} from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {usePersistedDataStore, useCompStore} from '../store';
import AdjustmentsSummaryDisplay from './AdjustmentsSummaryDisplay';
import CompNavigation from './CompNavigation';
import Tooltip from '@mui/material/Tooltip';
import debounce from 'lodash/debounce';
import InputAdornment from '@mui/material/InputAdornment';
import {NumericFormat} from "react-number-format";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import CaseNotesInput from './CaseNotesInput';
import CloseIcon from '@mui/icons-material/Close';
import { blue } from '@mui/material/colors';
import SettingsIcon from '@mui/icons-material/Settings';
import AdvancedSettings from './AdvancedSettings';
import AVTextField from './AVTextField';

function a11yProps(index) {
  // this some MUI shit. straight from chat. for the active / transitioned blue underline I believe.
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  // If you have an input you want to automatically be formatted, use this
  export const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, ...other } = props;
  
    return (
      <NumericFormat
        {...other}
        getInputRef={ref} // Pass the ref properly to the input element
        thousandSeparator
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        isnumericstring='true' // Correct camelCase for React prop
      />
    );
  });

const TableCustomToolbar = ({ globalCompRef, handleUpdateStateCallback, offerAmount, subject, view, handleProcessDBUpdate, viewCallback, runDate, regularAvg, compAvg, caseNotes, changeCaseNotes }) => {
  // this input changes from controlled to uncontrolled. Haven't really looked into it RE: casenotes. At some point do this.
    const [value, setValue] = useState(0); // using local state, and re-rendering the entir etable when changing.
    const [isEditing, setIsEditing] = useState(false);
    const getAdjustments = useCompStore((state)=> state.adjustments)
    const getEvalDate = usePersistedDataStore((state) => state.evalDate);
    const getNegotiationObj = usePersistedDataStore((state) => state.negotiationObj)
    const setNegotiationObj = usePersistedDataStore((state)=> state.setNegotiationObj)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const comp = useMemo(() => parseInt(queryParams.get('comp')) - 1 || 0, [queryParams]);
    const [selectedKey, setSelectedKey] = useState("selectedComps");
    const handleSelectChange = (event) => {
      setSelectedKey(event.target.value);
    };
    const [showAVs, setShowAVs] = useState(false);
    const [isThirdTabClicked, setIsThirdTabClicked] = useState(false);
      
    const [advancedSettings, setAdvancedSettings] = useState(() => {
        const storedSettings = localStorage.getItem('advancedSettings');
        return storedSettings
          ? JSON.parse(storedSettings)
          : {
              manualReview: true,
              defaultRunTypes: ['settled'],
              compFilters: [],
              dateRange: [null, null],
              squareFeetFilter: 40,
              salePriceFilter: 40,
              acreageFilter: 40,
            };
      });


      // This is duplicated code atm, same as in adv settings.
      // Tgus code should be the confirm which Re-runs the KNN
      const handleSettingsConfirm = (newSettings) => {
        setAdvancedSettings(newSettings);
        localStorage.setItem('advancedSettings', JSON.stringify(newSettings));
      };

    
    // calculate the sqft adjustment, if it is dyanmic, calculate it based on the IFMV value.
    const memoizedSqftAdjustment = useMemo(() => {
      // Check if the Sqft adjustment is "IFMV Linked"
      // console.log(getAdjustments.Sqft)
      if (getAdjustments.Sqft === "IFMV Linked") {
        // Access the IFMV value for the current comp
        const ifmvValue = getNegotiationObj.cases[comp]?.IFMV;
    
        if (ifmvValue) {
          // Calculate the adjustment factor based on IFMV value
          const sqftAdjustmentFactor = ifmvValue / 10000;
          return Math.round(sqftAdjustmentFactor); // Return the calculated adjustment
        } else {
          console.warn("IFMV value not found for the comp. Returning null.");
          return null;
        }
      }
    
      // Otherwise, return the direct Sqft adjustment value
      return getAdjustments.Sqft;
    }, [getAdjustments, comp]);

    // dialog state + functions.
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };

    // This function confirms your settings, and afterwards re-runs
    // Now that you have this working, idk that there is any reason to have this memoized?
    const memoizedOfferAmount = useMemo(() => Math.round(offerAmount, 0) || '', [offerAmount]);
    const memoizedCaseNotes = useMemo(() => caseNotes, [caseNotes]);
    const [isFocused, setIsFocused] = React.useState(false);
    const [isHovered, setIsHovered] = React.useState(false);

    const isTooltipOpen = isFocused || isHovered;

    // implement regex to ensure numeric inputs only -- for offer updating
    const handleChange = (event) => {
      const value = event.target.value;
      // Ensure only numeric inputs using regex
      if (/^\d*\.?\d*$/.test(value)) {
      debouncedOnValueChange(value);
      }
    };

    // Debounced function to call when input changes
    const debouncedOnValueChange = useCallback(
      debounce((value) => {
        const offerValue = parseInt(value) || null;
    
        // Check if the new value is the same as the existing value
        const currentOfferValue = getNegotiationObj.cases[comp]?.OfferValue;
        if (currentOfferValue === offerValue) {
          // console.log('No update needed, values are the same.');
          return; // Exit early if the values are the same
        }
    
        // Create a new negotiation object with a direct update
        const updatedCases = [...getNegotiationObj.cases];
        updatedCases[comp] = {
          ...updatedCases[comp],
          OfferValue: offerValue,
        };
    
        const updatedNegotiationObj = {
          ...getNegotiationObj,
          cases: updatedCases,
        };
    
        // Set the updated negotiation object
        setNegotiationObj(updatedNegotiationObj);
      }, 300),
      [comp, getNegotiationObj, setNegotiationObj]
    );
  
    // regularAvg
    // calculate pctAdj using ifmv - compAvg if view is not regular, if regular view use (ifmv-regularAvg)/ifmv
    const pctAdj = useMemo(() => {
      const IFMVTown = getNegotiationObj.cases[comp].IFMV;
      
      // Select the appropriate average based on selectedKey
      const selectedAvg = selectedKey === "selectedComps" ? compAvg.selectedComps : compAvg.top5;
    
      // Handle cases where selectedAvg might be a string
      const avg = typeof selectedAvg === "string" ? 0 : selectedAvg;
    
        return (avg - IFMVTown) / IFMVTown; // Flipped formula for other views
    }, [regularAvg, compAvg, view, comp, selectedKey]);

    // update this to be using the input value instead of the offer amount.
    const offerPctAdj = useMemo(() => {
      const IFMVTown = getNegotiationObj.cases[comp].IFMV;
      return (memoizedOfferAmount - IFMVTown) / IFMVTown
    }, [memoizedOfferAmount, comp]);
  
    // Determine the styling based on pctAdj value
    const pctAdjClass = pctAdj < 0 ? 'text-green-600' : 'text-red-500';

    // offer pct adj - handle color styling for exact matches (ie: the IFMV has some decimals so it may be -0.0000001 if equal.)
    const offerPctAdjClass = offerPctAdj < -0.0001 ? 'text-green-600' : 'text-red-500';

    const getRar = usePersistedDataStore((state) => state.rar);
    const getEq = usePersistedDataStore((state) => state.eq);
    const formatEvalDate = (dateString) => {
      if (!dateString) return ''; // Handle case where date is null or undefined
      const [year, month, day] = dateString.split('-'); // Split by '-'
      return `${month}/${day}/${year}`; // Rearrange to 'mm/dd/yyyy'
    };

    // minor inefficency on rerenders of eval date.
    // console.log('TableCustomToolbar rendered');
    const formattedEvalDate = formatEvalDate(getEvalDate);

    const handleViewChange = (event, newValue) => {
      setValue(newValue);
      viewCallback(newValue)
      if(newValue === 2 && selectedKey !== 'selectedComps') {
        setSelectedKey('selectedComps')
      }
      if (newValue === 2 && !isThirdTabClicked) {
        setIsThirdTabClicked(true);
      }
    };

    const handleDeleteThirdTab = (event) => {
      event.stopPropagation(); // Prevent triggering tab switch
      setIsThirdTabClicked(false);
      setValue(0)
      viewCallback(0)
    };

    return (
        <div className=''>
          {/* castenotes / chat input */}
          {/* chat piece to be built. */}
          <div className='flex flex-grow'>
            <CaseNotesInput
              initialValue={memoizedCaseNotes} // Pass the current value as initial
              onCaseNotesChange={changeCaseNotes} // Pass the change handler
            />
          </div>
      
          <div className="flex content-center items-center justify-between">
            {/* Section 1: Tabs navigation */}
            <Box
              className="flex self-end w-1/3 items-end"
            >
              {/* three selection tabs for regular, court, or scratch */}
              <Tabs
                value={value}
                onChange={handleViewChange}
                aria-label="basic tabs example"
                sx={{
                  minHeight: '30px',
                  borderBottom: 1,
                  borderColor: 'divider',
                  alignItems: 'flex-start', // Align tabs to the top
                }}
                TabIndicatorProps={{
                  sx: {
                    height: '2px',
                  },
                }}
              >
                <Tab
                  label="Analysis"
                  sx={{
                    minHeight: '30px',
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                  }}
                />
                <Tab
                  label="Court"
                  sx={{
                    minHeight: '30px',
                    padding: '4px 12px',
                    fontSize: '0.875rem',
                  }}
                />
                <Tab
                  label={
                    isThirdTabClicked ? (
                      <div className={`h-full items-center ${value===2 ? 'cursor-default' :'cursor-pointer'}`}
                        style={{
                          position: 'relative',
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '8px',
                          paddingRight: '8px',

                        }}
                      >
                        <span className='flex h-full items-center justify-center'>1</span>
                        <CloseIcon
                          sx={{
                            position: 'absolute',
                            top: '-4px',
                            right: '-10px',
                            fontSize: '1rem',
                            color: 'red',
                            cursor: 'pointer',
                          }}
                          onClick={handleDeleteThirdTab}
                        />
                      </div>
                    ) : (
                      <AddIcon 
                      sx={{
                        transition: 'color 0.2s',
                        color: 'inherit',
                        '&:hover': {
                          color: blue[700], // MUI primary blue on hover
                        },
                      }}
                      />
                    )
                  }
                  sx={{
                    minHeight: '30px',
                    fontSize: '0.875rem',
                    padding: '2px 8px', // Compact padding for smaller tab
                    minWidth: 'auto', // Prevent default tab width
                    backgroundColor: 'transparent',
                    '&:hover': !isThirdTabClicked && {
                      cursor: 'pointer',
                      backgroundColor: '#f0f0f0', // Gray background on hover
                    },
                  }}
                />
              </Tabs>
                  {/* DISPLAY A YELLOW HAZARD ICON NEXT TO CLIENT IF ITS A REPID CASE (subject.RepID !== '') */}
                  {/* Client name and SCAR Index # */}
                  <div className="text-sm ml-2 flex flex-col flex-grow justify-center w-[135px]">
                    {(getNegotiationObj.cases[comp].ClientName || getNegotiationObj.cases[comp].SCARIndexNumber) ? (
                      <>
                        {/* Client ClientName with ellipsis overflow and tooltip */}
                        {getNegotiationObj.cases[comp].ClientName ? (
                          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                            <span className="font-bold mr-1 pl-2">Client:</span>
                            <Tooltip
                              title={getNegotiationObj.cases[comp].ClientName}
                              disableHoverListener={getNegotiationObj.cases[comp].ClientName.length <= 12}
                            >
                              <span>{getNegotiationObj.cases[comp].ClientName}</span>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="text-xs opacity-50 pl-2">Client: N/A</div>  // {/* Placeholder if no client name */}
                        )}

                        {/* SCAR ID with placeholder if missing */}
                        {getNegotiationObj.cases[comp].SCARIndexNumber ? (
                          <div className="text-xs overflow-hidden text-ellipsis whitespace-nowrap">
                            <span className="font-bold mr-1 pl-2">SCAR ID:</span>
                            <Tooltip
                              title={getNegotiationObj.cases[comp].SCARIndexNumber}
                              disableHoverListener={getNegotiationObj.cases[comp].SCARIndexNumber.length <= 11}
                            >
                              <span>{getNegotiationObj.cases[comp].SCARIndexNumber}</span>
                            </Tooltip>
                          </div>
                        ) : (
                          <div className="text-xs opacity-50 rounded-lg pl-2">
                            <span className=' bg-yellow-500'>SCAR ID: N/A
                            </span>
                          </div>  // {/* Placeholder if no SCAR ID */}
                        )}

                        {/* Including subject starting AV here */}
                        <div className='flex'>
                        <span className="font-bold mr-1 pl-2 text-xs">Subj AV:</span>
                        <span className={`font-normal text-xs ${!subject?.PropertyAssessment ? 'text-red-500' : ''}`}>
                          {subject?.PropertyAssessment 
                            ? Math.round(subject.PropertyAssessment).toLocaleString() 
                            : 'No AV!'}
                        </span>
                        </div>
                      </>
                    ) : (
                      <>
                        {/* Placeholders if neither Name nor SCAR ID exists */}
                        <div className="text-xs opacity-50 pl-2">Client: N/A</div>
                        <div className="text-xs opacity-50 bg-yellow-500 rounded-lg pl-2">SCAR ID: N/A</div>
                      </>
                    )}
                  </div>
            </Box>

          {/* Section 2: Toolbar content */}
          <div className=" w-1/3 justify-center ">
            {/* First Column: Last Run and Regular Avg (takes 2/3 of the space) */}
                {/* The comp avg price type selection: */}
                <div className="flex relative font-bold text-sm items-center justify-center flex-grow">
                  <div className='flex flex-col cursor-pointer'>
                    <FormControl
                      variant="standard"
                      sx={{
                        display: "flex",
                        alignItems: "center", // Align FormControl contents to the top
                        height: "100%", // Ensure FormControl spans full height for alignment
                        marginBottom: "0px", // Remove unnecessary margin
                        paddingBottom: "0px", // Remove unnecessary padding
                        "& .MuiInput-underline:before": {
                          borderBottom: "none !important", // Remove underline by default
                        },
                        '&.MuiInputBase-root': {
                          paddingBottom: '0px', // Remove unnecessary padding
                        },
                        "& .MuiInput-underline:hover:before": {
                          borderBottom: "none !important", // Show underline on hover
                        },
                        "& .MuiInput-underline:after": {
                          borderBottom: "none !important", // Underline when focused
                        },
                        "&.Mui-disabled": {
                          color: "black !important", // Ensure text remains black when disabled
                        },
                      }}
                    >

                      <Select
                        labelId="comp-avg-select-label"
                        className="mt-0 !important py-0 !important"
                        value={selectedKey}
                        onChange={handleSelectChange}
                        disabled={value === 2}
                        renderValue={(selected) => {
                          if (selected === "selectedComps" && isNaN(compAvg.selectedComps)) {
                            return "Select Comps";
                          }
                          if (selected === "selectedComps") {
                            return "Selected Avg";
                          }
                          if (selected === "top5") {
                            return "Top Five Avg";
                          }
                          if (selected === "offerValue") {
                            return memoizedOfferAmount || isEditing ? "Offer Value" : "Add Offer";
                          }
                          return selected; // Fallback for any other potential values
                        }}
                        sx={{
                          "&.Mui-focused": {
                            backgroundColor: "transparent", // Ensure no background when focused
                          },
                          "& .MuiSelect-select:focus": {
                            backgroundColor: "transparent", // Transparent background inside the select wrapper
                          },
                          "&:hover": {
                            backgroundColor: "transparent",
                          },
                          "&:focus": {
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <MenuItem value="selectedComps">Selected Avg</MenuItem>
                        <MenuItem value="top5">Top Five Avg</MenuItem>
                        {memoizedOfferAmount || isEditing ? (
                          <MenuItem value="offerValue">Offer Value</MenuItem>
                        ) : (
                          <MenuItem value="offerValue">Add Offer</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                    {runDate && (
                      <span
                      className="w-full font-normal"
                      style={{
                        fontSize: "11px", // Small text
                        position: "absolute", // Position absolutely
                        bottom: "-8px", // Adjust to sit just below the input field
                        pointerEvents: "none", // Avoid interfering with clicks
                      }}
                    >
                        Last Run:{" "}
                        {/* Addition is for a dumb est timezone correction */}
                        {new Date(new Date(runDate).getTime() + 46400000).toLocaleDateString(
                          "en-US",
                          {
                            year: "2-digit",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        )}
                      </span>
                    )}
                  </div>

            {/* avg price and offer display with AV toggle - three diff sub AV things atm. (dumb) */}
            <div className="flex items-center justify-center">
              {selectedKey === "selectedComps" && !isNaN(compAvg.selectedComps) && (
                <AVTextField
                  value={compAvg.selectedComps}
                  label="AV"
                  showAVs={showAVs}
                  getRar={getRar}
                  isHidden={compAvg.selectedComps === "None selected"}
                  adornmentSymbol={showAVs ? "AV" : "$"}
                />
              )}
              {selectedKey === "top5" && (
                <AVTextField
                  value={compAvg.top5}
                  label="AV"
                  showAVs={showAVs}
                  getRar={getRar}
                  isHidden={compAvg.top5 === "None selected"}
                  adornmentSymbol="$"
                />
              )}
              {selectedKey === "offerValue" && (
                <div className="flex flex-col justify-start relative w-full ml-0.5">
                  <Tooltip
                    title={`Offer AV: ${Math.round(memoizedOfferAmount * getRar).toLocaleString()}`}
                    placement="bottom"
                    open={isTooltipOpen}
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -10],
                          },
                        },
                      ],
                    }}
                  >
                    <TextField
                      value={
                        // showAVs
                          // ? Math.round(memoizedOfferAmount * getRar).toLocaleString()
                          memoizedOfferAmount.toLocaleString()
                      }
                      onFocus={() => setIsFocused(true)}
                      onBlur={() => setIsFocused(false)}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      // onInput={() => {
                      //   if (showAVs) {
                      //     toast.error("You must toggle to $ to edit offer value");
                      //     setShowAVs(false);
                      //   }
                      // }}
                      onChange={(e) => {
                        // if (!showAVs) {
                          handleChange(e);
                        // }
                      }}
                      variant="outlined"
                      placeholder="Offer"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment
                            position="end"
                            className="mr-1 relative flex items-center cursor-pointer"
                            // onClick={(e) => {
                            //   e.preventDefault();
                            //   e.stopPropagation();
                            //   setShowAVs(!showAVs);
                            // }}
                          >
                            <div className="flex items-center relative">
                              {/* {showAVs ? <span className="z-10">AV</span> : <span className="z-10">$</span>} */}
                              <span className="z-10">$</span>
                            </div>
                          </InputAdornment>
                        ),
                        inputComponent: NumberFormatCustom,
                      }}
                      autoComplete="off"
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "0px 4px",
                          height: "32px",
                          maxWidth: "135px",
                          border: "1px solid transparent",
                          transition: "border-color 0.2s ease",
                          "&:hover": {
                            borderColor: "#000",
                          },
                        },
                        "& input": {
                          textAlign: "right",
                          padding: "0",
                          minWidth: "40px",
                          // width: `${
                          //   showAVs
                          //     ? Math.max(Math.round(memoizedOfferAmount * getRar).toString().length * 10, 40)
                          //     : Math.max(memoizedOfferAmount.toString().length * 10, 40)
                          // }px`,
                          width: `${
                              Math.max(memoizedOfferAmount.toString().length * 10, 40)
                          }px`,
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: "none",
                        },
                      }}
                    />
                  </Tooltip>
                </div>
              )}
            </div>
          
                {/* Only display the % if its less than 90% red. this code is probably dumb. */}
                {pctAdj > -0.6 
                ? selectedKey !== "offerValue" 
                  ? (
                    <div 
                      className="flex items-center justify-center h-full"
                      style={{
                        height: "100%", // Ensure height spans parent container
                      }}
                    >
                      <span
                        className={`text-xs ml-1 ${pctAdjClass}`}
                        style={{
                          display: "flex", // Flex ensures proper alignment
                          alignItems: "center",
                          justifyContent: "center", // Align within span if needed
                        }}
                      >
                        ({(pctAdj * 100).toFixed(1)}%)
                      </span>
                    </div>
                  )
                  : offerPctAdj > -0.6 
                  ? (
                    <div 
                      className="flex items-center justify-center h-full"
                      style={{
                        height: "100%", // Ensure consistent height
                      }}
                    >
                      <span
                        className={`text-xs ${offerPctAdjClass}`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center", // Center text in span
                        }}
                      >
                        ({(offerPctAdj * 100).toFixed(1)}%)
                      </span>
                    </div>
                  )
                  : (
                    <span 
                      className='text-xs ml-1 w-[44px]'
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {/* Empty space for alignment */}
                    </span>
                  )
                : (
                  <span 
                    className='text-xs ml-1 w-[18px]'
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {/* Empty space for alignment */}
                  </span>
                )
              }

              </div>
          </div>

            {/* Section 3: Comp navigation */}
            <div className="w-1/3 flex justify-end text-right">

                {/* Eval date and adjustments */}
              <div className='text-sm mr-2'>
                {/* could move this to its own component if you start doing more with the adj price */}
                {/* Eval date popover on top of the adjustments */}
                <div className='flex justify-end items-center text-xs'>
                  <span> <strong>Eval Date:</strong> {formattedEvalDate}</span>
                </div>
                <div className="flex justify-end items-center text-xs space-x-2">
                  <span>
                    <strong>RAR:</strong> {getRar}
                  </span>
                  <span>
                    <strong>EQ:</strong> {getEq}
                  </span>
                </div>
              <AdjustmentsSummaryDisplay adjustments={getAdjustments} sqftDisplay={memoizedSqftAdjustment} />
              </div>

              {/* Settings icon to open up adjustment settings (any other in-review settings to add?) */}
              <div className="items-center flex cursor-pointer text-gray-500 hover:text-blue-500"
              onClick={handleClickOpen}>
                <SettingsIcon/>
              </div>
              {/* repurpose this to be the adjustments popover code: */}
              {open &&
                <AdvancedSettings
                // open={activeDialog === 'settings'}
                open={open}
                onClose={handleClose}
                onConfirm={handleSettingsConfirm}
                initialSettings={advancedSettings}
                runAnalysis={handleProcessDBUpdate}
              />
                  // <Dialog open={open} onClose={handleClose}>
                  // {/* <DialogTitle>Adjustments</DialogTitle> */}
                  // {/* <DialogContent> */}
                    // <AdjustmentsDialog  open={open} onClose={handleClose}/>
                    
                  // {/* </DialogContent> */}

              // {/* </Dialog> */}
              }

              <CompNavigation
                globalCompRef={globalCompRef}
                handleUpdateStateCallback={handleUpdateStateCallback}
              />
            </div>
          </div>

      </div>
    );
};


export default TableCustomToolbar;